import styled from "styled-components";
import { breakpoints, colors } from "../styles/variables";

export const FormTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: ${colors.secondary};

  @media (min-width: ${breakpoints.sm}) {
    font-size: 2rem;
    margin-bottom: 2.25rem;
  }
`;
